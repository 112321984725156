// in src/NotFound.js
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";

const NotFound = () => {
  return (
    <Card>
      <Title title="Not Found" />
      <CardContent>
        <h1>Page not found or No permission to view</h1>
      </CardContent>
    </Card>
  );
};

export default NotFound;
