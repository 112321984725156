import useResourcesActions from "@/hooks/useResourcesActions";
import { isEmpty } from "lodash";
import React from "react";
import {
  EditGuesser,
  ListGuesser,
  NotFound,
  Resource,
  useAuthenticated,
} from "react-admin";
import CustomLoading from "../components/CustomLoading";
import ExercisesCreate from "../dashboard/exercises/ExercisesCreate";
import ExercisesEdit from "../dashboard/exercises/ExercisesEdit";
import ExercisesList from "../dashboard/exercises/ExercisesList";

const ExercisesResource = () => {
  useAuthenticated();

  const permissions = useResourcesActions({
    resource: "exercises",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (!permissions?.view) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="exercises"
      list={ExercisesList}
      create={ExercisesCreate}
      edit={ExercisesEdit}
    />
    // <Resource name="exercises" list={ListGuesser} create={ExercisesCreate} edit={EditGuesser} />
  );
};

export default ExercisesResource;
