import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomDeleteButton from "@/components/CustomDeleteButton";
import { Datagrid, List, ResourceProps, TextField } from "react-admin";

const FileListSound = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "file-sound",
  });

  return (
    <List {...props} perPage={50}>
      <Datagrid
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="id" label="Video" />
        {permissionActions?.canDelete && (
          <CustomDeleteButton resource="sounds" />
        )}
      </Datagrid>
    </List>
  );
};

export default FileListSound;
