import React from "react";

import { TagOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { useAuthenticated, useRedirect } from "react-admin";

const Dashboard = () => {
  useAuthenticated();
  const redirect = useRedirect();

  return (
    <Card
      style={{
        height: "100%",
        paddingTop: "4%",
      }}
    >
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/exercisetags")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "primary.main",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <TagOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "primary.main" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Exercise Tags
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Dashboard;
