import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomDeleteButton from "@/components/CustomDeleteButton";
import CustomListActions from "@/components/CustomListActions";

import CustomDateField from "@/components/CustomDateField";
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ResourceProps,
  TextField,
} from "react-admin";

const FeedsList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "feeds",
  });

  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        // rowClick="show"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="id" label="Mã bài viết" />
        <CustomDateField source="createdAt" label="Ngày đăng" />
        <TextField source="fullName" label="Tên đầy đủ" />
        <TextField source="userId" label="Mã người dùng" />
        <TextField source="description" label="Mô tả" />
        <BooleanField source={"deactive"} label="Ẩn" defaultValue="false" />

        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <CustomDeleteButton resource="feeds" />
        )}
      </Datagrid>
    </List>
  );
};

export default FeedsList;
