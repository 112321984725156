import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { isUndefined } from "lodash";
import React from "react";
import {
  SaveButton,
  localStorageStore,
  removeEmpty,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import db from "../provider/appProvider";

const CustomCreateButton = ({
  resource,
  identifier = "",
  overrideData = {},
  enableRefresh = true,
  recordId,
}: {
  resource: string;
  identifier?: string;
  overrideData?: {
    [property: string]: any;
  };
  enableRefresh?: boolean;
  recordId?: string;
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const [create] = useCreate();

  const auth = getAuth();

  const handleClick = async (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();

    if (resource === "users") {
      try {
        const res = await createUserWithEmailAndPassword(
          auth,
          data?.email,
          data?.email
        );
        await setDoc(doc(db, "users", res.user.uid), {
          email: data?.email,
          timeStamp: serverTimestamp(),
        });
      } catch (err) {
        console.log({ err });
      }
    } else if (resource === "feeds") {
      try {
        const addFeed = collection(db, "users", data?.userId, "feeds");

        await addDoc(addFeed, {
          fullName: data?.fullName,
          description: data?.description,
          userId: data?.userId,
          active: true,
          timestamp: serverTimestamp(),
        });
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "exercisetags") {
      try {
        await setDoc(
          doc(db, "exercise-tags", id),
          {
            tag: data?.tag,
            active: data?.active,
            vi: data?.vi,
            en: data?.en,
            timestamp: serverTimestamp(),
          },
          {}
        );
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "exercises") {
      try {
        const addExercises = collection(db, "exercises");
        await addDoc(addExercises, {
          description: data?.description || null,
          sound: data?.sound || null,
          title: data?.title || null,
          subtitle: data?.subtitle || null,
          video: data?.video || null,
          tag: data?.tag || null,
          image: data?.image || null,
          author: data?.author || null,
          order: data?.order || null,
          volume: data?.volume || null,
          isPreview: data?.isPreview || false,
          isMusicOn: data?.isMusicOn || false,
          active: data?.active || false,
          timestamp: serverTimestamp() || null,
        });
        notify(`Create ${resource} successfully!`);
        redirect("/" + resource);
        setTimeout(() => {
          enableRefresh && refresh();
        }, 50);
      } catch (err: any) {
        console.log({ exerciseError: err });
        notify(err?.message, {
          type: "error",
        });
      }
    } else {
      create(
        resource,
        {
          data: {
            ...removeEmpty(data),
            ...removeEmpty(overrideData),
            id: !isUndefined(id) ? id : recordId || data?.[identifier],
          },
        },
        {
          onSuccess() {
            notify(`Create ${resource} successfully!`);
            redirect("/" + resource);
            setTimeout(() => {
              enableRefresh && refresh();
            }, 50);
          },
          onError(error: any) {
            notify(error?.message, {
              type: "error",
            });
          },
        }
      );
    }
  };

  return (
    <SaveButton
      label="SAVE AND CREATE"
      onClick={handleClick}
      type="button"
      variant="text"
    />
  );
};

export default CustomCreateButton;
