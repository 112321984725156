import React from "react";

import CustomCreateButton from "@/components/CustomCreateButton";
import { Grid, TextField } from "@mui/material";
import {
  ArrayField,
  BooleanInput,
  Create,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ResourceProps,
  SelectArrayInput,
  SimpleForm,
  SingleFieldList,
  TextInput,
  Toolbar,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canCreate && <CustomCreateButton resource="feeds" />}
    </Toolbar>
  );
};

const CustomToolBar = () => {
  return (
    <Toolbar>
      <CustomCreateButton resource="feeds" identifier="fullName" />
    </Toolbar>
  );
};

const FeedsCreate = (props: ResourceProps["create"]) => {
  // const permissionActions = useResourcesActions({
  //   resource: "users",
  // });
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"fullName"}
              label="FULLNAME"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"description"}
              label="DESCRIPTION"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"userId"}
              label="USERID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              required
              source={"active"}
              label="Active"
              defaultValue={true}
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default FeedsCreate;
