import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { localStorageStore } from "react-admin";
import db from "./appProvider";
import firebaseAuthProvider from "./firebaseAuthProvider";

const getLocalEmail = () => {
  const localAuth = localStorageStore("1.0.0", "fitlive-admin").getItem("auth");
  try {
    const localData = JSON.parse(localAuth);
    if (localData?.user?.email) {
      return localData?.user?.email;
    }
    throw Error("Email not found");
  } catch (err) {
    throw err;
  }
};

export const getAppPermissions = async () => {
  try {
    let email;
    try {
      email = (await firebaseAuthProvider.getAuthUser()).email;
    } catch (_err) {
      email = getLocalEmail();
    }
    const q = query(
      collection(db, "users"),
      where("email", "==", email),
      where("active", "==", true)
    );

    let found: any = null;
    (await getDocs(q)).forEach((d) => {
      if (d.exists() && d.data()) {
        found = d.data();
      }
    });
    if (found) {
      const role = found?.roles?.[0];
      const docQuery = doc(db, "roles/" + role);
      const permission = await getDoc(docQuery);
      let permissionData = permission.data();

      if (!isEmpty(permissionData)) {
        if (role === "admin") {
          permissionData.users = {
            edited: true,
            deleted: true,
            created: true,
            exported: true,
            edit: ["*"],
            view: ["*"],
          };
          permissionData.roles = {
            edited: true,
            deleted: true,
            created: true,
            exported: true,
            edit: ["*"],
            view: ["*"],
          };
        }
        localStorageStore("1.0.0", "fitlive-admin").setItem(
          "permissions",
          JSON.stringify(permissionData)
        );
        return Promise.resolve(permissionData);
      }
    }
  } catch (err) {
    console.log({ err });
    return Promise.resolve({});
  }
};
export const checkAppAuth = async () => {
  try {
    const localAuth = localStorageStore("1.0.0", "fitlive-admin").getItem("auth");
    if (localAuth) {
      return Promise.resolve();
    } else {
      await firebaseAuthProvider.logout({});
      return Promise.reject("Auth expired");
    }
  } catch (error) {
    console.error("Error checking app auth:", error);
    return Promise.reject(error);
  }
};