import React, { useState } from "react";

import {
  SaveButton,
  useNotify,
  useRedirect,
  useRefresh,
  useDelete,
  useRecordContext,
  Resource,
} from "react-admin";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormContext } from "react-hook-form";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  collectionGroup,
  query,
  where,
  serverTimestamp,
  deleteField,
  deleteDoc,
} from "firebase/firestore";
import db from "../provider/appProvider";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseAuthProvider from "../provider/firebaseAuthProvider";
import { getThumbnail, deleteFileFromS3, listFiles } from "@/services/s3Service";

const CustomDeleteButton = (data: any) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const [deleteOne, { isLoading, error }] = useDelete();
  const auth = getAuth();
  let email;

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {

    

    if (data.resource === "feeds") {
      try {
        email = (await firebaseAuthProvider.getAuthUser()).email;
        if (email) {
          deleteOne("feeds", { id: record.id, previousData: record });
       
          await deleteDoc(doc(db, "users", `${record?.userId}`, "feeds", `${record?.id}`));
        
          // const updateitemRef = query(
          //   collection(db, "users", record?.userId, "feeds")
          // );

          // const itemSnapshot = await getDocs(updateitemRef);

          // itemSnapshot.forEach((doc) => {
          //   if (doc.id === record.id) {
          //     updateDoc(doc.ref, {
          //       id: deleteField(),
          //     });
          //   }
          // });
        }
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
    if (data.resource === "exercises") {
      try {
        email = (await firebaseAuthProvider.getAuthUser()).email;
        if (email) {
          deleteOne("exercises", { id: record.id, previousData: record });
          await deleteDoc(doc(db, "exercises", `${record?.id}`));
        }
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
    if (data.resource === "exercise-tags") {
      try {
        email = (await firebaseAuthProvider.getAuthUser()).email;
        if (email) {
          deleteOne("exercise-tags", { id: record.id, previousData: record });
          await deleteDoc(doc(db, "exercise-tags", `${record?.id}`));
        }
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
    if (data.resource === "images" || data.resource === "videos" || data.resource === "sounds") {
      
      try {
        email = (await firebaseAuthProvider.getAuthUser()).email;
        if (email) {
          const keyFile = data.resource;
          
          console.log('deleteAwsImages login :', email)
          const deleteAwsImages = await deleteFileFromS3({ folder: `exercises/${keyFile}/${record.id}`});
          deleteOne("file-image", { id: record.id, previousData: record });
          console.log('deleteAwsImages :', deleteAwsImages)
        }
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <>
      <Button
        variant="contained"
        color="error"
        disabled={isLoading}
        onClick={handleClick}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Xóa feed #"}
          {record.id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn muốn xóa vật phẩm này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDeleteButton;
