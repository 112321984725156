import useResourcesActions from "@/hooks/useResourcesActions";
import { awsConfig } from "@/provider/awsConfig";
import { uploadFile } from "@/services/s3Service";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AWS from "aws-sdk";
import React, { useRef, useState } from "react";
import { NotFound, useUpdate } from "react-admin";
import ReactPlayer from "react-player";
import slugify from "slugify";
import CustomLoading from "../../components/CustomLoading";

const s3 = new AWS.S3();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUpload = ({ folder }: { folder: string }) => {
  const [category, setCategory] = React.useState("exercises");
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>("");
  const refFile = useRef<any>(null);
  const [update] = useUpdate();
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const permissions = useResourcesActions({
    resource: "video",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (!permissions?.view) {
    return <NotFound title="No permission or Not found page" />;
  }

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    setErrorMessage("");
  };

  const handleUpload = async () => {
    if (!file) return;
    setProgress(0);
    let folders = "images";
    if (file.type.includes("video/")) folders = "videos";
    if (file.type.includes("audio/")) folders = "sounds";

    const fileName = slugify(file.name, {
      replacement: "-",
      remove: /[*+~.()'"!:@’/]/g,
      lower: true,
    });

    const params: AWS.S3.PutObjectRequest = {
      Body: file,
      Bucket: awsConfig.AWS_BUCKET,
      Key: `${category}/${folders}/${fileName}`,
    };

    s3.upload(params)
      .on("httpUploadProgress", (event) => {
        setProgress(Math.round((event.loaded / event.total) * 100));
      })
      .send((err: any, data: any) => {
        if (err) {
          setErrorMessage(err.message);
          setErrorOpen(true);
        } else {
          setOpen(true);
          setPreview("");
          setFile(null);
        }
      });
  };

  const removeFile = () => {
    refFile.current.value = null;
    setFile(null);
    setPreview("");
  };

  const handleFileChange = (files: FileList | null) => {
    if (files) {
      const fileRef = files[0];
      setFile(fileRef);
      const objectUrl = URL.createObjectURL(fileRef);
      setPreview(objectUrl);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Box
          sx={{ minWidth: 250 }}
          style={{
            backgroundColor: "#fff",
            padding: "10px 26px 10px 12px",
            borderRadius: 10,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={handleChange}
            >
              <MenuItem value={"exercises"}>Exercises</MenuItem>
              <MenuItem value={"avatars"}>Avatars</MenuItem>
              <MenuItem value={"challenges"}>Challenges</MenuItem>
              <MenuItem value={"feeds"}>Feeds</MenuItem>
              <MenuItem value={"notifyMenus"}>NotifyMenus</MenuItem>
              <MenuItem value={"stories"}>Stories</MenuItem>
            </Select>
          </FormControl>
        </Box>
        Choose a file to upload
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            ref={refFile}
            type="file"
            onChange={(e) => handleFileChange(e.target.files)}
          />
        </Button>
        <hr />
        {preview && (
          <>
            {file?.type.includes("image/") && <img src={preview} width={300} />}
            {file?.type.includes("video/") && (
              <ReactPlayer url={preview} controls width="300px" />
            )}
            {file?.type.includes("audio/") && (
              <ReactPlayer url={preview} controls width="300px" height="50px" />
            )}
            <hr />
            <Stack spacing={2} direction="row">
              <Button onClick={handleUpload}>Save</Button>
              <Button onClick={removeFile}>Remove</Button>
            </Stack>
          </>
        )}
      </header>
      <Box sx={{ width: "100%" }}>
        {progress > 0 && <LinearProgressWithLabel value={progress} />}
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Upload completed successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={10000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {"Upload Failed: " + errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FileUpload;
