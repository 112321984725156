
import * as CryptoJS from "crypto-js";
const REVERSE = "strRevert@123";

export const encrypt = (inputString: string = "", salt: string = REVERSE) => {
  return CryptoJS.AES.encrypt(inputString, salt).toString();
};

export const decrypt = (inputString: string = "", salt: string = REVERSE) => {
  return CryptoJS.AES.decrypt(inputString, salt).toString(CryptoJS.enc.Utf8);
};
