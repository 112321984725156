import CustomEditButton from "@/components/CustomEditButton";
import { awsConfig } from "@/provider/awsConfig";
import { getThumbnail, listFiles, uploadFile } from "@/services/s3Service";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  DeleteButton,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  ResourceProps,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useWatch } from "react-hook-form";
import slugify from "slugify";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && <CustomEditButton resource="exercises" />}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ExercisesEdit = (props: ResourceProps["edit"]) => {
  const [dataVideos, setDataVideos] = useState<any>([]);
  const [dataSounds, setDataSounds] = useState<any>([]);
  const [dataImages, setDataImages] = useState<any>([]);
  const [loading, setLoading] = useState<any>("");
  const [file, setFile] = useState<any>(null);
  const [filebase64, setFileBase64] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const refFile = useRef<any>(null);
  const [tag, setTag] = useState<any>();

  const permissionActions = useResourcesActions({
    resource: "exercises",
  });
  function convertFile(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      if (fileRef) {
        setFile(fileRef);
        const objectUrl = URL.createObjectURL(fileRef);
      }

      const fileType: string = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
      // const selectFile = e.target.files[0];
    }
  }
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleUpload = async () => {
    let folders = "images";

    if (filebase64.indexOf("image/") > -1) folders = "images";
    if (filebase64.indexOf("video/") > -1) folders = "videos";
    if (filebase64.indexOf("audio/") > -1) folders = "sounds";

    const fileName = slugify(file?.name, {
      replacement: "-",
      remove: /[*+~.()'"!:@’/]/g,
      lower: true,
    });
    const params: AWS.S3.PutObjectRequest = {
      Body: file,
      Bucket: awsConfig.AWS_BUCKET,
      Key: `exercises/${folders}/${fileName}`,
    };
    // let partSize = file.size / (10 * 1024 * 1024);
    await uploadFile(params)
      .then((data) => {
        console.log("data", data);
        // setProgress(Math.round((100 * data.loaded) / data.total))
        setOpen(true);
        setFileBase64(null);
        handleGetAws();
      })
      .catch((err) => console.error(err));
  };

  const removeFile = () => {
    refFile.current.value = null;
    setFile(null);
    setFileBase64(null);
  };

  const toChoices = (items: string[]) =>
    items.map((item) => ({ id: item, name: item }));

  const handleGetAws = async () => {
    const dataAws = await listFiles({ folder: "exercises/videos" });
    const dataAwsSounds = await listFiles({ folder: "exercises/sounds" });
    const dataAwsImages = await listFiles({ folder: "exercises/images" });
    setDataVideos(dataAws);
    setDataSounds(dataAwsSounds);
    setDataImages(dataAwsImages);
  };

  if (loading == "") {
    handleGetAws();
    setLoading(true);
  }

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"id"}
              label="ID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <DateInput
              source="createdAt"
              label="Ngày đăng"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              required
              source={"tag"}
              label="TAG"
              style={{
                width: "90%",
              }}
            /> */}
            <ReferenceInput
              reference="exercise-tags"
              required
              source="tag"
              style={{
                width: "90%",
              }}
            >
              <SelectInput
                emptyText="None"
                source="tag"
                optionValue="tag"
                optionText="tag"
                label="Exercise Tag"
                style={{
                  width: "100%",
                }}
                onChange={(event) => setTag(event.target.value)}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SelectInput
              style={{
                width: "90%",
              }}
              source="caloLevel"
              label="Mức độ năng lượng"
              choices={[
                {
                  id: "low",
                  name: "Thấp",
                },
                {
                  id: "medium",
                  name: "Trung bình",
                },
                {
                  id: "high",
                  name: "Cao",
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <SelectInput
              style={{
                width: "90%",
              }}
              source="video"
              label="Video chính"
              choices={toChoices(dataVideos)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SelectInput
              style={{
                width: "90%",
              }}
              source="warmUpVideo"
              choices={toChoices(dataVideos)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              source="isPreview"
              label="Hiển thị ở trang chính"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              source="isMusicOn"
              label="Bật nhạc nền"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <NumberInput
              source="order"
              label="Số thứ tự"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <NumberInput
              source="volume"
              label="âm lượng"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"author"}
              label="Tác giả"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"title"}
              label="Tiêu đề"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              required
              source={"sound"}
              label="sound"
              style={{
                width: "90%",
              }}
            /> */}
            <SelectInput
              style={{
                width: "90%",
              }}
              source="Chọn file âm thanh nền"
              choices={toChoices(dataSounds)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              required
              source={"active"}
              label="Cho phép hiển thị ở mobile"
              defaultValue={true}
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6}>
            <TextInput
              multiline
              required
              source={"description"}
              label="Mô tả"
              style={{
                width: "100%",
                minHeight: 100,
                padding: "2%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              required
              source={"image"}
              label="image"
              style={{
                width: "90%",
              }}
            /> */}

            <SelectInput
              style={{
                width: "90%",
              }}
              source="image"
              choices={toChoices(dataImages)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                ref={refFile}
                type="file"
                onChange={(e) => convertFile(e.target.files)}
              />
            </Button>
            {filebase64 && (
              <>
                {filebase64.indexOf("image/") > -1 && (
                  <div style={{ marginTop: 10 }}>
                    <img src={filebase64} width={100} />
                  </div>
                )}
              </>
            )}
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button onClick={handleUpload}>Upload</Button>
              <Button onClick={removeFile}>Remove</Button>
            </Stack>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Upload completed successfully!
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          alignItems="flex-start"
          style={{
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <ArrayInput
            required
            source="extraInfo"
            style={{
              alignItems: "flex-start",
              marginLeft: "5%",
              marginRight: "5%",
              marginTop: "2%",
            }}
            label={"Thông tin thêm"}
            fullWidth
            defaultValue={[
              {
                order: 0,
                property: "",
                description: "",
              },
            ]}
          >
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="description"
                label="Mô tả"
                style={{
                  width: "90%",
                }}
                multiline
                InputProps={{
                  style: {
                    height: "150px",
                    textAlign: "left",
                  },
                }}
              />
              <NumberInput
                source="order"
                label="Số Thứ tự"
                style={{
                  width: "90%",
                }}
              />
              <TextInput
                source="property"
                label="Tên thuộc tính"
                style={{
                  width: "90%",
                }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default ExercisesEdit;
