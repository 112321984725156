import { RAFirebaseOptions } from "react-admin-firebase";

const firebaseOptions: RAFirebaseOptions = {
  persistence: "session",
  dontAddIdFieldToDoc: true,
  disableMeta: true,
  renameMetaFields: {
    created_at: "createdAt",
    created_by: "createdBy",
    updated_at: "updatedAt",
    updated_by: "updatedBy",
  },
};

export default firebaseOptions;
