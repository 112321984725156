import React from "react";

import useResourcesActions from "@/hooks/useResourcesActions";
import {
  AdminPanelSettings,
  DashboardCustomizeOutlined,
  Groups3TwoTone,
  TagOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Menu } from "react-admin";
import { useLocation } from "react-router-dom";

const CustomMenu = () => {
  const names = useLocation()?.pathname;
  const permissionActions = useResourcesActions({
    resource: "users",
  });

  return (
    <Menu>
      <Menu.DashboardItem
        leftIcon={
          <DashboardCustomizeOutlined
            style={{
              color: names === "/" ? "black" : "gray",
            }}
          />
        }
        to="/"
        primaryText={
          <Typography color={names === "/" ? "black" : "gray"}>
            Trang chủ
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <TagOutlined
            style={{
              color: names?.startsWith("/exercisetags") ? "blue" : undefined,
            }}
          />
        }
        to="/exercisetags"
        primaryText={
          <Typography
            color={names?.startsWith("/exercisetag") ? "blue" : "gray"}
          >
            Nhóm tập luyện
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <TagOutlined
            style={{
              color: names?.startsWith("/exercises") ? "blue" : undefined,
            }}
          />
        }
        to="/exercises"
        primaryText={
          <Typography color={names?.startsWith("/exercises") ? "blue" : "gray"}>
            Tập luyện
          </Typography>
        }
      />
      {permissionActions.canCreate &&
        permissionActions.canDelete &&
        permissionActions.canCreate && (
          <Menu.Item
            leftIcon={
              <Groups3TwoTone
                style={{
                  color: names?.startsWith("/users") ? "blue" : undefined,
                }}
              />
            }
            to="/users"
            primaryText={
              <Typography color={names?.startsWith("/users") ? "blue" : "gray"}>
                Người dùng
              </Typography>
            }
          />
        )}
      {permissionActions.canCreate &&
        permissionActions.canDelete &&
        permissionActions.canCreate && (
          <Menu.Item
            leftIcon={
              <TagOutlined
                style={{
                  color: names?.startsWith("/feeds") ? "blue" : undefined,
                }}
              />
            }
            to="/feeds"
            primaryText={
              <Typography color={names?.startsWith("/feeds") ? "blue" : "gray"}>
                Feeds
              </Typography>
            }
          />
        )}
      {permissionActions.canCreate &&
        permissionActions.canDelete &&
        permissionActions.canCreate && (
          <Menu.Item
            leftIcon={
              <TagOutlined
                style={{
                  color: names?.startsWith("/file") ? "blue" : undefined,
                }}
              />
            }
            to="/file"
            primaryText={
              <Typography color={names?.startsWith("/file") ? "blue" : "gray"}>
                Tập tin
              </Typography>
            }
          />
        )}
    </Menu>
  );
};

export default CustomMenu;
