import { isEmpty } from "lodash";
import { usePermissions } from "react-admin";

const useResourcesActions = ({
  resource,
}: {
  resource: string;
}): IPermissionActions => {
  const permissions = usePermissions();
  // const resourcePermissions = permissions?.permissions?.[resource] || {};
  // const permissionView: string[] = resourcePermissions?.view || [];
  // const permissionEdit: string[] = resourcePermissions?.edit || [];
  // const canEditAll = permissionView?.includes("*");
  // const canViewAll = permissionEdit?.includes("*");
  return {
    canExport: true, //resourcePermissions?.exported,
    canDelete: true, // resourcePermissions?.deleted,
    canCreate: true, //resourcePermissions?.created,
    canEdit: true, // resourcePermissions?.edited,
    view: true,
    // isEmpty(permissionView)
    //   ? {}
    //   : {
    //     vi: permissionView?.includes("vi") || canViewAll,
    //   },
    edit: true,
    // {
    //   vi: permissionEdit?.includes("vi") || canEditAll,
    // },
    loading: permissions?.isLoading,
  };
};

export default useResourcesActions;
