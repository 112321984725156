import useResourcesActions from "@/hooks/useResourcesActions";
import React from "react";
import { Resource } from "react-admin";

import CustomLoading from "../components/CustomLoading";
import FeedsCreate from "../dashboard/feeds/FeedsCreate";
import FeedsEdit from "../dashboard/feeds/FeedsEdit";
import FeedsList from "../dashboard/feeds/FeedsList";

const FeedsResource = () => {
  const permissions = useResourcesActions({
    resource: "feeds",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  return (
    <Resource
      name={"feeds"}
      list={FeedsList}
      edit={FeedsEdit}
      create={FeedsCreate}
    />
  );
};

export default FeedsResource;
