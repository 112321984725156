import React from "react";

import CustomEditButton from "@/components/CustomEditButton";
import appUserRoles from "@/constants/roles";
import { Grid } from "@mui/material";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  ReferenceArrayInput,
  ResourceProps,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && <CustomEditButton resource="users" />}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const UserEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "users",
  });

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={6}>
            <TextInput
              required
              source={"email"}
              label="EMAIL"
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <BooleanInput
              required
              source={"active"}
              label="Active"
              defaultValue={true}
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={4}>
            <TextInput
              required={false}
              source={"username"}
              label="USERNAME"
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6} sm={4} md={4}>
            <BooleanInput source="roles.user" label="Người dùng" />
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <BooleanInput source="roles.pt" label="Hướng dẫn viên(PT)" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
