import { awsConfig } from "@/provider/awsConfig";
import AWS from "aws-sdk";

const s3 = new AWS.S3();

export const getThumbnail = ({
  folder = "",
  thumbnail,
}: {
  folder: string;
  thumbnail: string;
}) => {
  let params: any = {
    Bucket: awsConfig.AWS_BUCKET,
    Key: folder + "/" + thumbnail,
  };
  const url: string = s3.getSignedUrl("getObject", params);
  return url;
};

export const listFiles = async ({ folder }: { folder: string }) => {
  const params: AWS.S3.ListObjectsRequest = {
    Bucket: awsConfig.AWS_BUCKET,
    Delimiter: "/",
    Prefix: folder + "/",
  };

  const data = await s3.listObjects(params).promise();
  let res: string[] = [];
  for (let index = 0; index < (data?.Contents || [])?.length || 0; index++) {
    const imgPath = data?.Contents?.[index]["Key"] || "";
    if (imgPath?.split("/").pop()) {
      res.push(imgPath?.split("/").pop() || "");
    }
  }
  return res;
};

export const uploadFile = (
  params: AWS.S3.PutObjectRequest
): Promise<AWS.S3.ManagedUpload.SendData> => {
  return new Promise((resolve, reject) => {
    s3.upload(params, (error, data) => {
      if (error) {
        console.error("Upload failed:", error);
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
};

//delete file from s3 bucker
export const deleteFileFromS3 = ({
  folder = ""
}: {
  folder: string;
}) => {
  let params: any = {
    Bucket: awsConfig.AWS_BUCKET,
    Key: folder ,
  };
  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (error, data) => {
      if (error) {
  
  
        reject(error);
      } else {
        resolve(data);

      }
    });
  });

};
