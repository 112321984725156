import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomDeleteButton from "@/components/CustomDeleteButton";
import {
  Datagrid,
  ImageField,
  List,
  ResourceProps,
  TextField,
} from "react-admin";

const FileListImage = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "file-image",
  });

  return (
    <List
      {...props}
      // actions={<CustomListActions permissionActions={permissionActions} />}
      // exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        // rowClick="show"
        // rowClick="edit"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <ImageField
          source="image"
          label="Image"
          sx={{
            "& img": { maxWidth: 100, maxHeight: 100, objectFit: "contain" },
          }}
        />
        <TextField source="id" label="Video" />
      </Datagrid>
    </List>
  );
};

export default FileListImage;
