import { decrypt } from "@/utils/securityUtil";
import { ConfigurationOptions, config } from "aws-sdk";

declare module "aws-sdk" {
  interface ConfigurationOptions extends AWSConfig {
    //
  }
}

export const awsConfig: AWSConfig = {
  AWS_ACCESS_KEY_ID: decrypt(process.env.REACT_APP_AWS_ACCESS_KEY_ID) || "",
  AWS_SECRET_ACCESS_KEY:
    decrypt(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY) || "",
  AWS_REGION: decrypt(process.env.REACT_APP_AWS_REGION) || "",
  AWS_BUCKET: decrypt(process.env.REACT_APP_AWS_BUCKET) || "",
};

config.update({
  credentials: {
    accessKeyId: awsConfig.AWS_ACCESS_KEY_ID,
    secretAccessKey: awsConfig.AWS_SECRET_ACCESS_KEY,
  },
  region: awsConfig.AWS_REGION,
} as ConfigurationOptions);
