import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledCircularProgress = styled(CircularProgress)({
  marginTop: "20%",
});

const CustomLoading = () => {
  return (
    <StyledBox>
      <StyledCircularProgress color="secondary" />
    </StyledBox>
  );
};

export default CustomLoading;
