import { FirebaseAuthProvider } from "react-admin-firebase";
import firebaseConfig from "./firebaseConfig";
import firebaseOptions from "./firebaseOptions";

const firebaseAuthProvider = FirebaseAuthProvider(
  firebaseConfig,
  firebaseOptions
);

export default firebaseAuthProvider;
