import useResourcesActions from "@/hooks/useResourcesActions";
import { isEmpty } from "lodash";
import React from "react";
import { NotFound, Resource } from "react-admin";
import CustomLoading from "../components/CustomLoading";
import ExerciseTagsList from "../dashboard/exerciseTags";
import ExerciseTagsCreate from "../dashboard/exerciseTags/ExerciseTagCreate";
import ExerciseTagsEdit from "../dashboard/exerciseTags/ExerciseTagEdit";

const ExerciseTagResource = () => {
  const permissions = useResourcesActions({
    resource: "exercisetags",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (!permissions?.view) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource name="exercisetags" list={ExerciseTagsList} create={ExerciseTagsCreate} edit={ExerciseTagsEdit} />
  );
};

export default ExerciseTagResource;
