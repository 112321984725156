import React from "react";
import { RaRecord, useRecordContext } from "react-admin";

interface DateFieldProps {
  source: string;
  format?: Intl.DateTimeFormatOptions;
  label?: string;
}

const CustomDateField: React.FC<DateFieldProps> = ({ source, format }) => {
  const record = useRecordContext<RaRecord>();

  if (!record) return null;

  const timestamp = record[source];

  // Ensure timestamp has seconds and nanoseconds properties
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    return <span>Invalid timestamp</span>;
  }

  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);

  const formattedDate = `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")} ${String(date.getDate()).padStart(
    2,
    "0"
  )}/${date.toLocaleString("vi-VN", { month: "short" })}/${date.getFullYear()}`;

  return <span>{formattedDate}</span>;
};

CustomDateField.defaultProps = {
  format: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
};

export default CustomDateField;
