import { styled } from "@mui/system";
import React from "react";
import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const StyledTopToolbar = styled(TopToolbar)({
  justifyContent: "flex-end",
  marginBottom: 16,
});

const StyledExportButton = styled(ExportButton)({
  marginRight: 20,
});

const CustomListActions = ({ permissionActions }: { permissionActions: IPermissionActions }) => (
  <StyledTopToolbar>
    {permissionActions?.canExport && <StyledExportButton />}
    {permissionActions.canExport && <ImportButton />}
    {permissionActions?.canCreate && <CreateButton />}
  </StyledTopToolbar>
);

export default CustomListActions;
