import React, { useState } from "react";
import { uniqBy } from "lodash";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  LegacyDataProvider,
} from "react-admin";
import { FirebaseDataProvider } from "react-admin-firebase";
import firebaseConfig from "./firebaseConfig";
import firebaseOptions from "./firebaseOptions";
import { getThumbnail, uploadFile, listFiles } from "@/services/s3Service";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import db from "../provider/appProvider";

const IDS: {
  [key: string]: string;
} = {
  tags: "exercisetags",
  // users: "users",
  // feeds: "feeds",
};

const customDataProvider = (): DataProvider | LegacyDataProvider => {
 
  const defaultProvider = FirebaseDataProvider(firebaseConfig, firebaseOptions);
  
  return {
    getList : async (resource: string, params: GetListParams) => {
      
      if(resource === "feeds"){
      
       
        let dataArr : any = [];
        let dataNew  : any = {};
        const museums = query(collectionGroup(db, 'feeds'), );
        const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, ' => ', doc.data());
            dataNew = doc.data()
            dataNew.id = doc.id
            dataArr.push(dataNew);
        });

        const response = {
          data: dataArr,
          total: dataArr.length,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
        };
        // console.log("response 1", response);
        
        const id = IDS[resource] || "id";
        const data = uniqBy(response?.data, id) || [];
        const duplicateLength = (response?.data?.length || 0) - data?.length;
        const total = (response?.total || 0) - duplicateLength;
 
    
    
        return response;
        // return Promise.resolve(result);
      }else if(resource === "exercisetags"){
      
       
        let dataArr : any = [];
        let dataNew  : any = {};
        const museums = query(collectionGroup(db, 'exercise-tags'), );
        const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, ' => ', doc.data());
            dataNew = doc.data()
            dataNew.id = doc.id
            dataArr.push(dataNew);
        });

        const response = {
          data: dataArr,
          total: dataArr.length,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
        };
        // console.log("response 1", response);
        
        const id = IDS[resource] || "id";
        const data = uniqBy(response?.data, id) || [];
        const duplicateLength = (response?.data?.length || 0) - data?.length;
        const total = (response?.total || 0) - duplicateLength;
 
    
    
        return response;
        // return Promise.resolve(result);
      } else if(resource === "file-video"){
        const dataAws = await listFiles({ folder: "exercises/videos" });
        // const getdataAws = await getThumbnail({ folder: "exercises/videos", thumbnail:'cardio.mp4'  });
        const toChoices = (items: string[]) =>
        items.map((item) => ({ id: item, name: item, video: getThumbnail({ folder: "exercises/videos", thumbnail:item })}));

        // const toChoices = (items: string[]) =>
        // items.map((item) => ({ id: item, name: item }));
        
        const response = {
          data: toChoices(dataAws),
          total: toChoices(dataAws).length,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
        };
      
        const id = IDS[resource] || "id";
        const data = uniqBy(response?.data, id) || [];
        const duplicateLength = (response?.data?.length || 0) - data?.length;
        const total = (response?.total || 0) - duplicateLength;
 
        return response;
        // return Promise.resolve(result);
      } else if(resource === "file-image"){
        const dataAwsImages = await listFiles({ folder: "exercises/images" });
       
        const toChoices = (items: string[]) =>
        items.map((item) => ({ id: item, name: item, image: getThumbnail({ folder: "exercises/images", thumbnail:item })}));
        
        const response = {
          data: toChoices(dataAwsImages),
          total: toChoices(dataAwsImages).length,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
        };
        
        const id = IDS[resource] || "id";
        const data = uniqBy(response?.data, id) || [];
        const duplicateLength = (response?.data?.length || 0) - data?.length;
        const total = (response?.total || 0) - duplicateLength;
 
        return response;
        // return Promise.resolve(result);
      } else if(resource === "file-sound"){
        const dataAwsSounds = await listFiles({ folder: "exercises/sounds" });
 
        const toChoices = (items: string[]) =>
        items.map((item) => ({ id: item, name: item }));
        
        const response = {
          data: toChoices(dataAwsSounds),
          total: toChoices(dataAwsSounds).length,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
        };
 
        const id = IDS[resource] || "id";
        const data = uniqBy(response?.data, id) || [];
        const duplicateLength = (response?.data?.length || 0) - data?.length;
        const total = (response?.total || 0) - duplicateLength;
 
        return response;
        // return Promise.resolve(result);
      } else {
        const response: GetListResult<any> | void = await FirebaseDataProvider(
          firebaseConfig,
          firebaseOptions
        )
          .getList(resource, params)
          .then((res: any) => res)
          .catch((_err: any) => {
            return Promise.resolve({
              data: [],
              total: 0,
              pageInfo: {
                hasNextPage: false,
                hasPreviousPage: false,
              },
            });
          });
          
      const id = IDS[resource] || "id";
      const data = uniqBy(response?.data, id) || [];
      const duplicateLength = (response?.data?.length || 0) - data?.length;
      const total = (response?.total || 0) - duplicateLength;
      const page = params?.pagination?.page;
      const perPage = params.pagination.perPage;
      const result = {
        ...response,
        data: data || [],
        total: total,
        pageInfo: {
          hasNextPage: page < total / perPage,
          hasPreviousPage: page > 1,
        },
      };


      return Promise.resolve(result);
      }
    
      
    },
  
    getOne: defaultProvider.getOne,
    getMany: defaultProvider.getMany,
    getManyReference: defaultProvider.getManyReference,
    update: defaultProvider.update,
    updateMany: defaultProvider.updateMany,
    create: defaultProvider.create,
    delete: defaultProvider.delete,
    deleteMany: defaultProvider.deleteMany,
  };
};

export default customDataProvider;
