import React from "react";

import CustomCreateButton from "@/components/CustomCreateButton";
import { Grid } from "@mui/material";
import {
  BooleanInput,
  Create,
  ResourceProps,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

const CustomToolBar = () => {
  return (
    <Toolbar>
      <CustomCreateButton resource="exercisetags" identifier="tag" />
    </Toolbar>
  );
};

const TagCreate = (props: ResourceProps["create"]) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              required
              source={"id"}
              label="ID"
              style={{
                width: "100%",
                margin: "2.5%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <BooleanInput
              required
              source={"active"}
              label="Active"
              defaultValue={true}
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              required
              source={"en"}
              label="EN"
              style={{
                width: "100%",
                margin: "2.5%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextInput
              required
              source={"vi"}
              label="VI"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              required
              source={"tag"}
              label="TAG"
              style={{
                width: "100%",
                margin: "2.5%",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default TagCreate;
