import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { Admin, Resource, combineDataProviders } from "react-admin";
import { Route } from "react-router-dom";
import "./App.css";
import "./provider/awsConfig";

import CustomLayout from "@/components/CustomLayout";
import CustomLoading from "@/components/CustomLoading";
import NotFound from "@/components/NotFound";

import authProvider from "@/provider/authProvider";
import customDataProvider from "@/provider/customDataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import i18nProvider from "./provider/i18nProvider";

import Dashboard from "@/dashboard";
import ExercisesResource from "@/resources/ExercisesResource";
import ExerciseTagResource from "@/resources/ExerciseTagResource";
import FeedsResource from "@/resources/FeedsResource";
import FileResource from "@/resources/FileResource";
import UserResource from "@/resources/UserResource";

const theme = createTheme();

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Admin
          loading={CustomLoading}
          disableTelemetry
          catchAll={NotFound}
          title="Admin Dashboard"
          dataProvider={customDataProvider()}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={CustomLayout}
        >
          <Resource name="dashboard" list={Dashboard} />
          <Resource name="exercisetags" list={ExerciseTagResource} />
          <Resource name="users" list={UserResource} />
          <Resource name="feeds" list={FeedsResource} />
          <Resource name="exercises" list={ExercisesResource} />
          <Resource name="file" list={FileResource} />
        </Admin>
      </ThemeProvider>
    </div>
  );
}

export default App;
