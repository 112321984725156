import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "../i18n/en";
import vietnamMessages from "../i18n/vi";

const messages = {
  en: () => import("../i18n/en").then((messages) => messages.default),
};

export default polyglotI18nProvider(
  (locale) => {
    if (locale === "en") {
      return messages[locale]();
    }

    // Always fallback on english
    return vietnamMessages;
  },
  "vi",
  [
    { locale: "vi", name: "Vietnam" },
    { locale: "en", name: "English" },
  ]
);
