
const Colors = {
  background: '#FFFFFF',
  whiteFA: "#FAFAFA",
  greyBD: "#BDBDBD",

  primary: '#2D9CDB',
  primaryDark: "#2F80ED"
}

export default Colors;