// in src/NotFound.js
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useLogout } from "react-admin";
import { Button } from "@mui/material";

const CustomEmpty = () => {
  const logout = useLogout();
  return (
    <Card>
      <Title title="Not Found" />
      <CardContent>
        <h1>No Item Available or No permissions</h1>
        <Button onClick={logout}>Logout</Button>
      </CardContent>
    </Card>
  );
};

export default CustomEmpty;
