import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomDeleteButton from "@/components/CustomDeleteButton";
import CustomEmpty from "@/components/CustomEmpty";
import CustomListActions from "@/components/CustomListActions";
import Colors from "@/themes/Colors";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ResourceProps,
  TextField,
  TextInput,
} from "react-admin";

const filters = [
  <TextInput label="Search Tag" source="exercise-tags" alwaysOn />,
];

const TagList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "exercise-tags",
  });
  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      filters={filters}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
      empty={<CustomEmpty />}
    >
      <Datagrid
        id="exercise-tags"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: Colors.whiteFA,
                borderWidth: 0,
              }
            : {
                backgroundColor: Colors.whiteFA,
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: Colors.primary,
            color: Colors.background,
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: Colors.greyBD,
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="id" color={Colors.primaryDark} label="ID" />
        <TextField source={"vi"} label="VI" />
        <TextField source={"tag"} label="TAG" />
        <TextField source={"en"} label="EN" />
        <BooleanField source={"active"} label="Active" defaultValue="true" />

        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          // <DeleteButton mutationMode="pessimistic" />
          <CustomDeleteButton resource="exercise-tags" />
        )}
      </Datagrid>
    </List>
  );
};

export default TagList;
