import { localStorageStore } from "react-admin";
import { ReactAdminFirebaseAuthProvider } from "react-admin-firebase/dist/providers/AuthProvider";
import firebaseAuthProvider from "./firebaseAuthProvider";
import { checkAppAuth, getAppPermissions } from "./providerHelpers";

const authProvider: ReactAdminFirebaseAuthProvider = {
  ...firebaseAuthProvider,
  getPermissions: getAppPermissions,
  checkAuth: checkAppAuth,
  login: (params) => {
    return firebaseAuthProvider
      .login(params)
      .then((auth) => {
        localStorageStore("1.0.0", "fitlive-admin").setItem(
          "auth",
          JSON.stringify(auth)
        );
        return { redirectTo: "/" };
      })
      .catch((err) => {
        throw new Error("Auth error");
      });
  },
  logout: (params) => {
    return firebaseAuthProvider
      .logout(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        localStorageStore("1.0.0", "fitlive-admin").removeItem("auth");
        localStorageStore("1.0.0", "fitlive-admin").removeItem("permissions");
      });
  },
};

export default authProvider;
