import React from "react";

import CustomEditButton from "@/components/CustomEditButton";
import { Grid } from "@mui/material";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  ResourceProps,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && (
        <CustomEditButton resource="exercisetags" identifier="exercisetag" />
      )}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const TagEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "exercisetags",
  });

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        {/* <Grid container spacing={3} alignItems="flex-start">
          {permissionActions?.view && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit}
                required
                source={"vi"}
                label="VI"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
            

            
          )}

        </Grid> */}
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"id"}
              label="ID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              required
              source={"active"}
              label="Active"
              defaultValue={true}
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"tag"}
              label="TAG"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"en"}
              label="EN"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"vi"}
              label="VI"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default TagEdit;
