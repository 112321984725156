import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import React from "react";
import { useLogout } from "react-admin";

const StyledMenuItem = styled(MenuItem)({
  borderRadius: 4,
  borderColor: "gray",
  backgroundColor: "white",
  margin: 2,
  paddingTop: 2,
  paddingBottom: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

const StyledExitIcon = styled(ExitIcon)({
  marginRight: 2,
});

const CustomLogout = () => {
  const logout = useLogout();
  const handleClick = () => logout();

  return (
    <StyledMenuItem onClick={handleClick}>
      <StyledExitIcon color="error" />
      <Typography color="error">Logout</Typography>
    </StyledMenuItem>
  );
};

export default CustomLogout;
