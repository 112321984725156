import { VerifiedUserOutlined } from "@mui/icons-material";
import { MenuItem, Typography } from "@mui/material";
import React from "react";
import { AppBar, Layout, UserMenu } from "react-admin";
import CustomLogout from "./CustomLogout";
import CustomMenu from "./CustomMenu";

const StyledMenuItem = ({ children }: { children: React.ReactNode }) => (
  <MenuItem
    onClick={() => {}}
    style={{
      borderRadius: 4,
      borderColor: "gray",
      backgroundColor: "white",
      margin: 2,
      paddingTop: 8,
      paddingBottom: 8,
      justifyContent: "space-between",
    }}
  >
    {children}
  </MenuItem>
);

const MyUserMenu = () => (
  <UserMenu>
    <StyledMenuItem>
      <VerifiedUserOutlined color="primary" style={{ marginRight: 12 }} />
      <Typography color="primary.main">Profile</Typography>
    </StyledMenuItem>
    <CustomLogout />
  </UserMenu>
);

const CustomAppBar = () => <AppBar color="primary" userMenu={<MyUserMenu />} />;

const CustomLayout = (props: any) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
);

export default CustomLayout;
