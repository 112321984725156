import React, { useState } from "react";
import { getThumbnail, uploadFile, listFiles } from "@/services/s3Service";
import CustomEditButton from "@/components/CustomEditButton";
import { Grid } from "@mui/material";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  ReferenceArrayInput,
  ResourceProps,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  DateInput,
  NumberInput,
  SelectField,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useWatch } from "react-hook-form";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && <CustomEditButton resource="file-video" />}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const FileEdit = (props: ResourceProps["edit"]) => {
  const [dataVideos, setDataVideos] = useState<any>([]);
  const [dataSounds, setDataSounds] = useState<any>([]);
  const [dataImages, setDataImages] = useState<any>([]);
  const [loading, setLoading] = useState<any>("");
  const [filebase64, setFileBase64] = useState<any>("");

  const permissionActions = useResourcesActions({
    resource: "file-video",
  });

  console.log('permissionActions', permissionActions)
  const toChoices = (items: string[]) =>
    items.map((item) => ({ id: item, name: item }));

  const handleGetAws = async () => {
    const getdataAws = await getThumbnail({ folder: "exercises/videos", thumbnail:'cardio.mp4'  });
    // const dataAws = await listFiles({ folder: "exercises/videos" });
    // const dataAwsSounds = await listFiles({ folder: "exercises/sounds" });
    // const dataAwsImages = await listFiles({ folder: "exercises/images" });
    // setDataVideos(dataAws);
    // setDataSounds(dataAwsSounds);
    // setDataImages(dataAwsImages);
    setFileBase64(getdataAws);
  };

  

  if (loading == "") {

   
    handleGetAws();
    setLoading(true);
  }

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"id"}
              label="ID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          <TextInput
              required
              source={"name"}
              label="Name"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
     
      </SimpleForm>
  
          {/* <video controls>
            <source src={filebase64} />
          </video> */}
            
    </Edit>
  );
};

export default FileEdit;
