import useResourcesActions from "@/hooks/useResourcesActions";
import { isEmpty } from "lodash";
import React from "react";
import { NotFound, Resource } from "react-admin";

import CustomLoading from "../components/CustomLoading";
import UserCreate from "../dashboard/users/UserCreate";
import UserEdit from "../dashboard/users/UserEdit";
import UserList from "../dashboard/users/UserList";

const UserResource = () => {
  const permissions = useResourcesActions({
    resource: "users",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (!permissions?.view) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
    />
  );
};

export default UserResource;
