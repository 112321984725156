// import vietnamMesages from "ra-language-vietnamese";
import vietnamesMessages from "@completejavascript/ra-language-vietnamese";
export default {
  ...vietnamesMessages,
  resources: {
    users: {
      name: "Tên |||| Tên",
      fields: {
        username: "Tên",

      },
    },
   
  },
  pos: {
    dashboard: {
      
    },
  },
};
