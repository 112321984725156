import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomListActions from "@/components/CustomListActions";
import Colors from "@/themes/Colors";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ReferenceField,
  ResourceProps,
  TextField,
} from "react-admin";

const UserList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "users",
  });

  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="email" color={Colors.primaryDark} label="Email" />
        <BooleanField source="active" label="Active" />
        <BooleanField source={"roles.user"} label="Người dùng" />
        <BooleanField source={"roles.pt"} label="Huấn luyện viên" />
        <TextField source="province" label="Tỉnh/Thành phố" />
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  );
};

export default UserList;
