import React from "react";

import CustomDeleteButton from "@/components/CustomDeleteButton";
import { Datagrid, List, ResourceProps, TextField } from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const FileListVideo = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({ resource: "file-video" });

  return (
    <List {...props} perPage={50}>
      <Datagrid
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        rowStyle={(_, index) => ({
          backgroundColor: index % 2 ? "#FAFAFA" : "#EAEAEA",
          borderWidth: 0,
        })}
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        {/* <VideoField source="Video" /> */}
        <TextField source="id" label="Name" />
        {permissionActions?.canDelete && (
          <CustomDeleteButton resource="videos" />
        )}
      </Datagrid>
    </List>
  );
};

export default FileListVideo;
